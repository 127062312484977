<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb>
    <el-backtop target=".el-main"></el-backtop>
    <div class="search">
      <div style="display: flex; flex-wrap: wrap">
        <el-select
          v-model="rewardType"
          placeholder="请选择状态"
          @change="rewardTypeList"
        >
          <el-option label="审核通过" value="2"></el-option>
          <el-option label="审核拒绝" value="3"></el-option>
        </el-select>
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 220px; margin-right: 10px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input placeholder="请输入手机号" v-model="phone"></el-input>
        <el-select
          v-model="activityId"
          placeholder="请选择期号"
          style="width: 220px; margin-right: 10px"
        >
          <el-option
            v-for="item in activityOption"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <div>
          <el-button type="primary" @click="searchList">搜索</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </div>
      </div>
    </div>

    <div class="search_fifter_activity">
      <el-button
        round
        v-for="(item, i) in activityOption.slice(0, 6)"
        :key="i"
        @click="handleActivity(item)"
      >
        {{ item }}
      </el-button>
      <p>奖励总和：{{ allRewards }}</p>
    </div>

    <div class="table_con" style="height: calc(100vh - 200px)">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="phoneArea"
          label="区号"
          width="90"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="120">
        </el-table-column>
        <el-table-column prop="userName" label="真实姓名" width="100">
        </el-table-column>
        <el-table-column prop="activityId" label="活动期号" width="90">
        </el-table-column>
        <el-table-column prop="wallet" label="钱包地址" width="220">
        </el-table-column>
        <el-table-column prop="normalRewards" label="常规奖励" width="90">
        </el-table-column>
        <el-table-column prop="extraRewards" label="额外奖励" width="90">
        </el-table-column>
        <el-table-column prop="activateNum" label="激活人数" width="80">
        </el-table-column>
        <el-table-column prop="examineStatus" label="审核状态" width="80">
        </el-table-column>
        <el-table-column prop="examineReason" label="拒绝原因" width="200">
        </el-table-column>
        <el-table-column prop="examineTime" label="审核时间" width="170">
        </el-table-column>
        <el-table-column prop="auditor" label="审核人" width="140">
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="100">
          <template slot-scope="scope">
            <el-button @click="handleDetail(scope.row)" type="text" size="small"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { hideLoading, showLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      multipleSelection: [],
      tableData: [],

      valueArea: "",
      options: [],
      phone: "",
      rewardType: "",
      activityId: "",
      activityOption: [],
      allRewards: "",

      BreadcrumbCon: [
        {
          name: "资产审核",
        },
        {
          name: "火财盒",
        },
        {
          name: "历史审核列表",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 200, //要传过去的数据 每页多少条数据
      pageSizes: [200, 500, 1000, 5000, 10000, 20000, 50000, 100000],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.huoCaiHeGetMatchBoxList,
      method: "POST",
      params: JSON.stringify({
        pageNum: 0,
        pageSize: 200,
        phone: "",
        phoneArea: this.phoneAreaROLE,
        examineStatus: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.data.matchBoxList.list) {
          this.tableData = res.data.data.matchBoxList.list;
          this.pageLength = res.data.data.matchBoxList.total;
          this.pageSize = res.data.data.matchBoxList.pageSize;
          res.data.data.matchBoxList.list.map((item) => {
            item.examineTime = format_time_date(item.examineTime);
            if (item.examineStatus == 2) {
              item.examineStatus = "审核通过";
            } else if (item.examineStatus == 3) {
              item.examineStatus = "审核拒绝";
            }
          });

          this.allRewards = res.data.data.allRewards;
        }

        hideLoading();

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);

    const optActivity = {
      url: reqUrl.huoCaiHeGetActivityNOList,
      method: "POST",
      resFunc: (res) => {
        this.activityOption = res.data.data;
        hideLoading();

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optActivity);
  },

  methods: {
    searchList() {
      if (this.phone || this.valueArea || this.rewardType || this.activityId) {
        showLoading();
        const opt = {
          url: reqUrl.huoCaiHeGetMatchBoxList,
          method: "POST",
          params: JSON.stringify({
            pageNum: 0,
            pageSize: 200,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            examineStatus: this.rewardType,
            activityId: this.activityId,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.data.matchBoxList.list) {
              this.tableData = res.data.data.matchBoxList.list;
              this.pageLength = res.data.data.matchBoxList.total;
              this.pageSize = res.data.data.matchBoxList.pageSize;
              res.data.data.matchBoxList.list.map((item) => {
                item.examineTime = format_time_date(item.examineTime);
                if (item.examineStatus == 2) {
                  item.examineStatus = "审核通过";
                } else if (item.examineStatus == 3) {
                  item.examineStatus = "审核拒绝";
                }
              });

              this.allRewards = res.data.data.allRewards;
            }

            hideLoading();

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    // 类别
    rewardTypeList() {
      showLoading();
      const opt = {
        url: reqUrl.huoCaiHeGetMatchBoxList,
        method: "POST",
        params: JSON.stringify({
          pageNum: 0,
          pageSize: 200,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          examineStatus: this.rewardType,
          activityId: this.activityId,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.data.matchBoxList.list) {
            this.tableData = res.data.data.matchBoxList.list;
            this.pageLength = res.data.data.matchBoxList.total;
            this.pageSize = res.data.data.matchBoxList.pageSize;
            res.data.data.matchBoxList.list.map((item) => {
              item.examineTime = format_time_date(item.examineTime);
              if (item.examineStatus == 2) {
                item.examineStatus = "审核通过";
              } else if (item.examineStatus == 3) {
                item.examineStatus = "审核拒绝";
              }
            });

            this.allRewards = res.data.data.allRewards;
          }

          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleActivity(activityId) {
      showLoading();
      const opt = {
        url: reqUrl.huoCaiHeGetMatchBoxList,
        method: "POST",
        params: JSON.stringify({
          pageNum: 0,
          pageSize: 200,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          examineStatus: this.rewardType,
          activityId: activityId,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.data.matchBoxList.list) {
            if (res.data.data.matchBoxList.list) {
              this.tableData = res.data.data.matchBoxList.list;
              this.pageLength = res.data.data.matchBoxList.total;
              this.pageSize = res.data.data.matchBoxList.pageSize;
              res.data.data.matchBoxList.list.map((item) => {
                item.examineTime = format_time_date(item.examineTime);
                if (item.examineStatus == 2) {
                  item.examineStatus = "审核通过";
                } else if (item.examineStatus == 3) {
                  item.examineStatus = "审核拒绝";
                }
              });

              this.allRewards = res.data.data.allRewards;
            }
          }

          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleDetail(row) {
      this.$router.push({
        path: "/FireWealthBox/Detail",
        query: {
          userId: row.userId,
          activityId: row.activityId,
          page: "history",
        },
      });
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.huoCaiHeGetMatchBoxList,
        method: "POST",
        params: JSON.stringify({
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          examineStatus: this.rewardType,
          activityId: this.activityId,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.data.matchBoxList.list) {
            this.tableData = res.data.data.matchBoxList.list;
            this.pageLength = res.data.data.matchBoxList.total;
            this.pageSize = res.data.data.matchBoxList.pageSize;
            this.currentPage = data.pageIndex;
            res.data.data.matchBoxList.list.map((item) => {
              item.examineTime = format_time_date(item.examineTime);
              if (item.examineStatus == 2) {
                item.examineStatus = "审核通过";
              } else if (item.examineStatus == 3) {
                item.examineStatus = "审核拒绝";
              }
            });
          }

          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    reset() {
      if (this.phone || this.valueArea || this.rewardType || this.activityId) {
        this.phone = "";
        this.valueArea = "";
        this.rewardType = "";
        this.activityId = "";

        // 重置后重新执行
        showLoading();
        const opt = {
          url: reqUrl.huoCaiHeGetMatchBoxList,
          method: "POST",
          params: JSON.stringify({
            pageNum: 0,
            pageSize: 200,
            phone: "",
            phoneArea: this.phoneAreaROLE,
            examineStatus: 2,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.data.matchBoxList.list) {
              this.tableData = res.data.data.matchBoxList.list;
              this.pageLength = res.data.data.matchBoxList.total;
              this.pageSize = res.data.data.matchBoxList.pageSize;
              this.currentPage = data.pageIndex;
              res.data.data.matchBoxList.list.map((item) => {
                item.examineTime = format_time_date(item.examineTime);
                if (item.examineStatus == 2) {
                  item.examineStatus = "审核通过";
                } else if (item.examineStatus == 3) {
                  item.examineStatus = "审核拒绝";
                }
              });
            }

            hideLoading();

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .el-input {
    width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .el-select {
    margin-right: 10px;
  }
}

.search_fifter_activity {
  display: flex;
  align-items: center;

  .el-button {
    padding: 8px 20px;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  p {
    margin-left: 20px;
    color: red;
  }
}

.pagination {
  margin-bottom: 10px;
}

.operation {
  display: flex;
  flex-direction: column;
  color: #b4b4b4;
  font-weight: bold;

  .el-button + .el-button {
    margin-left: 0;
    margin-top: 2px;
  }

  .el-button {
    border: 1px #ccc solid;
    color: #666;
    background: #d9d9d9;
  }
}
</style>
